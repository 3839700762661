const koTranslations = {
  translation: {
    title: '완벽한 원 그리기 챌린지',
    subtitle: '원 그리기의 예술을 마스터하세요! 🎯✨',
    instruction: '빨간 점을 중심으로 완벽한 원을 그려보세요',
    totalAttempts: '총 시도 횟수',
    highScore: '최고 점수',
    newRecord: '새로운 기록!',
    shareButton: '점수 공유하기',
    shareTitle: '완벽한 원 챌린지',
    shareText: {
      score: '🎯 완벽한 원 챌린지에서 {{score}}%를 달성했어요!',
      highScore: '🏆 최고 점수: {{score}}%',
      attempts: '🔄 총 시도 횟수: {{count}}',
      invitation: '제 점수를 이길 수 있나요? 지금 도전해보세요! 👇'
    },
    copied: '클립보드에 복사되었습니다 ✨',
    copyright: '© 2024 Perfect Circle Challenge. All rights reserved.',
    seo: {
      pageTitle: '완벽한 원 - 원 그리기 게임',
      pageSubtitle: '그리기 실력을 테스트해보세요',
      metaDescription: '이 브라우저 게임에서 완벽한 원 그리기에 도전하세요! 정밀도를 테스트하고 전 세계 플레이어들과 경쟁해보세요.',
      keywords: '완벽한 원, 원 게임, 그리기 게임, 원 그리기, 재미있는 게임, 온라인 원 그리기, 원 챌린지, 그리기 실력, 정밀 게임'
    },
    failed: '미완성 원'
  }
};

export default koTranslations;
