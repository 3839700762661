const frTranslations = {
  translation: {
    title: 'Défi du Cercle Parfait',
    subtitle: 'Maîtrisez l\'Art du Cercle! 🎯✨',
    instruction: 'Dessinez un cercle parfait autour du point rouge',
    totalAttempts: 'Tentatives Totales',
    highScore: 'Meilleur Score',
    newRecord: 'Nouveau Record!',
    shareButton: 'Partager le Score',
    shareTitle: 'Défi du Cercle Parfait',
    shareText: {
      score: '🎯 J\'ai obtenu {{score}}% au Défi du Cercle Parfait!',
      highScore: '🏆 Mon Meilleur Score: {{score}}%',
      attempts: '🔄 Tentatives Totales: {{count}}',
      invitation: 'Pouvez-vous faire mieux? Essayez maintenant! 👇'
    },
    copied: 'Copié dans le presse-papiers ✨',
    copyright: '© 2024 Perfect Circle Challenge. Tous droits réservés.',
    seo: {
      pageTitle: 'Cercle Parfait - Jeu de Dessin de Cercle',
      pageSubtitle: 'Testez vos Talents de Dessin',
      metaDescription: 'Relevez le défi de dessiner un cercle parfait dans ce jeu de navigateur captivant. Testez votre précision et affrontez le monde entier!',
      keywords: 'cercle parfait, jeu de cercle, jeu de dessin, dessiner cercle, jeu amusant, dessin de cercle en ligne, défi cercle, talent de dessin, jeu de précision'
    },
    failed: 'Cercle Incomplet'
  }
};

export default frTranslations; 