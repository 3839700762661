const jaTranslations = {
  translation: {
    title: '完璧な円を描くチャレンジ',
    subtitle: '円を描く芸術をマスターしよう！🎯✨',
    instruction: '赤い点を中心に完璧な円を描いてください',
    totalAttempts: '総試行回数',
    highScore: 'ハイスコア',
    newRecord: '新記録達成！',
    shareButton: 'スコアをシェア',
    shareTitle: '完璧な円チャレンジ',
    shareText: {
      score: '🎯 完璧な円チャレンジで{{score}}%を達成しました！',
      highScore: '🏆 自己ベスト: {{score}}%',
      attempts: '🔄 総試行回数: {{count}}',
      invitation: '私のスコアに挑戦してみませんか？今すぐトライ！👇'
    },
    copied: 'クリップボードにコピーしました ✨',
    copyright: '© 2024 Perfect Circle Challenge. All rights reserved.',
    seo: {
      pageTitle: '完璧な円 - 円を描くゲーム',
      pageSubtitle: '描画スキルをテストしよう',
      metaDescription: 'このブラウザゲームで完璧な円を描く挑戦をしよう！精密さを試し、世界中のプレイヤーと競い合おう。',
      keywords: '完璧な円, 円ゲーム, 描画ゲーム, 円を描く, 面白いゲーム, オンライン円描画, 円チャレンジ, 描画スキル, 精密ゲーム'
    },
    failed: '未完成の円'
  }
};

export default jaTranslations;
