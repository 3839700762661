const esTranslations = {
  translation: {
    title: 'Desafío del Círculo Perfecto',
    subtitle: '¡Domina el Arte de Dibujar Círculos! 🎯✨',
    instruction: 'Dibuja un círculo perfecto alrededor del punto rojo',
    totalAttempts: 'Intentos Totales',
    highScore: 'Mejor Puntuación',
    newRecord: '¡Nuevo Récord!',
    shareButton: 'Compartir Puntuación',
    shareTitle: 'Desafío del Círculo Perfecto',
    shareText: {
      score: '🎯 ¡He conseguido {{score}}% en el Desafío del Círculo Perfecto!',
      highScore: '🏆 Mi Mejor Puntuación: {{score}}%',
      attempts: '🔄 Intentos Totales: {{count}}',
      invitation: '¿Puedes superarme? ¡Inténtalo ahora! 👇'
    },
    copied: 'Copiado al portapapeles ✨',
    copyright: '© 2024 Perfect Circle Challenge. Todos los derechos reservados.',
    seo: {
      pageTitle: 'Círculo Perfecto - Juego de Dibujar Círculos',
      pageSubtitle: 'Prueba tus Habilidades de Dibujo',
      metaDescription: '¡Acepta el reto de dibujar un círculo perfecto en este adictivo juego de navegador! Prueba tu precisión y compite con jugadores de todo el mundo.',
      keywords: 'círculo perfecto, juego de círculos, juego de dibujo, dibujar círculo, juego divertido, dibujar círculo online, desafío círculo, habilidad de dibujo, juego de precisión'
    },
    failed: 'Círculo Incompleto'
  }
};

export default esTranslations; 