const enTranslations = {
  translation: {
    title: 'Perfect Circle Drawing Challenge',
    subtitle: 'Master the Art of Circle Drawing! 🎯✨',
    instruction: 'Create a perfect circle by drawing around the red dot',
    totalAttempts: 'Total Attempts',
    highScore: 'High Score',
    newRecord: 'New Record!',
    shareButton: 'Share Score',
    shareTitle: 'Perfect Circle Challenge',
    shareText: {
      score: '🎯 I scored {{score}}% in the Perfect Circle Challenge!',
      highScore: '🏆 My Best Score: {{score}}%',
      attempts: '🔄 Total Attempts: {{count}}',
      invitation: 'Can you beat my score? Try now! 👇'
    },
    copied: 'Copied to clipboard ✨',
    copyright: '© 2024 Perfect Circle Challenge. All rights reserved.',
    seo: {
      pageTitle: 'Perfect Circle - Draw a Perfect Circle Game',
      pageSubtitle: 'Test Your Circle Drawing Skills',
      metaDescription: 'Challenge yourself to draw the perfect circle in this addictive browser game. Test your precision and compete with others worldwide!',
      keywords: 'perfect circle, circle game, drawing game, write circle, circle drawing, funny game, draw circle online, circle challenge, drawing skills, precision game'
    },
    failed: 'Incomplete Circle'
  },
  
};

export default enTranslations;
