const zhTranslations = {
  translation: {
    title: '完美圆形挑战',
    subtitle: '掌握画圆的艺术！🎯✨',
    instruction: '围绕红点画一个完美的圆',
    totalAttempts: '总尝试次数',
    highScore: '最高分',
    newRecord: '新纪录！',
    shareButton: '分享成绩',
    shareTitle: '完美圆形挑战',
    shareText: {
      score: '🎯 我在完美圆形挑战中获得了 {{score}}% 的成绩！',
      highScore: '🏆 我的最高分：{{score}}%',
      attempts: '🔄 总尝试次数：{{count}}',
      invitation: '来挑战我的分数吧！现在就试试 👇'
    },
    copied: '已复制到剪贴板 ✨',
    copyright: '© 2024 Perfect Circle Challenge. All rights reserved.',
    seo: {
      pageTitle: '完美圆形 - 画圆游戏',
      pageSubtitle: '测试你的绘画技巧',
      metaDescription: '在这个有趣的浏览器游戏中挑战自己画出完美的圆！测试你的精确度，与全球玩家一较高下。',
      keywords: '完美圆形, 画圆游戏, 绘画游戏, 画圆, 趣味游戏, 在线画圆, 圆形挑战, 绘画技巧, 精确度游戏'
    },
    failed: '未完成的圆'
  }
};
export default zhTranslations;

