const ruTranslations = {
  translation: {
    title: 'Испытание Идеального Круга',
    instruction: 'Нарисуйте идеальный круг вокруг красной центральной точки',
    totalAttempts: 'Всего попыток',
    highScore: 'Лучший результат',
    newRecord: 'Новый рекорд!',
    shareButton: 'Поделиться результатом',
    shareTitle: 'Испытание Идеального Круга',
    shareText: {
      score: '🎯 Я достиг(ла) {{score}}% сходства в Испытании Идеального Круга!',
      highScore: '🏆 Мой лучший результат: {{score}}%',
      attempts: '🔄 Всего попыток: {{count}}',
      invitation: 'Брось мне вызов! 👇'
    },
    copied: 'Скопировано в буфер обмена ✨',
    copyright: '© 2024 Испытание Идеального Круга. Все права защищены.',
    seo: {
      pageTitle: 'Испытание Идеального Круга',
      pageSubtitle: 'Проверь свою точность | Творческая игра',
      metaDescription: 'Примите вызов нарисовать идеальный круг! Получайте мгновенные оценки точности с помощью ИИ в этой творческой игре.',
      keywords: 'идеальный круг,игра рисование,тест руки,испытание рисунком,творческая игра,тест на точность,рисование круга,художественная игра,онлайн игра,интерактивная игра'
    }
  }
};

export default ruTranslations; 